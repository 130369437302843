import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const ModalProjectFlowchartConsulting = ({ data }) => {
  const { labelRow5 } = data.contentfulProjectFlowchartItems
  const content_shortRow5 = data.contentfulProjectFlowchartItems.childContentfulProjectFlowchartItemsShortRow5RichTextNode.json
  
  let options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className='mb-8 mt-4'>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className='mb-6 mt-3'>{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className='mb-6'>{children}</p>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }
    return (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div>
        {modal ? (
          <div className="flex close-modal-icon-wrapper w-full items-end"><Link to={closeTo}>
            <div className="close-modal-icon">+</div>
          </Link></div>
        ) : (
          <Link to="/">Zurück zur Website</Link>
        )}

        <h2 className="mb-8 mt-8">Projektabschnitt {labelRow5}</h2>
        <p>
          {documentToReactComponents(content_shortRow5)}
        </p>
      </div>
    )}
  </ModalRoutingContext.Consumer>
  )
}
export default ModalProjectFlowchartConsulting

export const ModalConsultingQuery = graphql`
  query {
      contentfulProjectFlowchartItems(slug: {eq: "project-flowchart"}) {
        childContentfulProjectFlowchartItemsShortRow5RichTextNode {
          json
        }
        labelRow5
      }
  }
`